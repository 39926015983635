<template>
    <div class="w-full h-full p-16">
        <h1>Chat</h1>
        
        <div class="my-8">
            <div 
                v-for="(message,index) in accountChatMessages"
                :key="'m_'+index"
                class="mb-4">
                
                <div class="p-2 bg-yellow-200 mb-2">
                    {{message.message}}
                </div>
                <div class="text-sm opacity-50">
                    Sent by {{message.sender}}
                </div>
            </div>
        </div>
        
        <textarea 
            v-model="newMessage"
            class="w-full h-64 border mb-2"
            autofocus
            ref="autofocus"
        />
        
        <button 
            @click="sendMessage()"
            class="button submit"
            :disabled="loading == 'sending'">
            
            Send Message
        </button>
        
        <button 
            @click="disconnect()"
            class="button ml-2">
            
            disconnect
        </button>
        
    </div>
</template>


<script>
export default {
    computed: {
        user(){
            return this.$store.getters.getUser;
        },
        
        accountChatMessages(){
            return this.$store.getters.getAccountChatMessages;
        },
    },
    
    data () {
        return {
            loading: null,
            newMessage: '',
        }
    },
    
    methods: {
        sendMessage() {
            const message = {
                type: 'accountChatMessage',
                message: this.newMessage,
            };
            
            this.$store.dispatch('webSocketSend', message);
            
            this.newMessage = '';
            this.$refs.autofocus.focus();
        },
        
        disconnect(){
            console.log('disconnect called');
            
            if (window.wSConnection.readyState === WebSocket.OPEN) {
                console.log('is open');
                window.wSConnection.close(1000, 'Deliberate disconnection');
                console.log(window.wSConnection);
            }
            else {
                console.log('is not open');
            }
        }
    },
    
    // created(){
    //     console.log('window.wSConnection');
    //     console.log(window.wSConnection);
    // }
}
</script>
